const { CaseType } = require("../../../../constants/caseType");

const getProductWarnings = (t, product, caseType, productReturn) => {
  const {
    safeTubeNeededInReturning,
    isDamageProductReturnRequired,
    isReturnable,
    isAnesthetics,
  } = product || {};

  const customerIsReturningProduct = productReturn.returnValue;

  const newWarnings = [];

  // safe tube warning
  if (
    safeTubeNeededInReturning === true &&
    caseType === CaseType.SUSPECTED_PRODUCT_DEFECT
  ) {
    newWarnings.push({ id: "safe-tube-warning-text", text: t("safeTubeInfo") });
  }

  // Damaged product return required
  if (
    isDamageProductReturnRequired === true &&
    customerIsReturningProduct === false &&
    caseType === CaseType.DAMAGED_PRODUCT
  ) {
    newWarnings.push({
      id: "damaged-product-return-required-text",
      text: t("damagedProductReturnRequiredWarning"),
    });
  }

  // non returnable
  if (
    isReturnable === false &&
    customerIsReturningProduct === true &&
    caseType === CaseType.PRODUCT_DELIVERY
  ) {
    newWarnings.push({
      id: "non-returnable-text",
      text: t("nonReturnableProductWarning"),
    });
  }

  // Anesthetics
  if (
    isAnesthetics === true &&
    customerIsReturningProduct === true &&
    caseType !== CaseType.SUSPECTED_PRODUCT_DEFECT
  ) {
    newWarnings.push({
      id: "anesthetics-text",
      text: t("anestheticsWarning"),
    });
  }

  return newWarnings;
};

module.exports = { getProductWarnings };
