import { ProductUtil } from "@oriola-origo/origo-common-client-lib";

export const getProductDisplayName = (product, lang) => {
  if (product.customerAddedProduct) {
    return product.productName;
  }
  const nameParts = [
    ProductUtil.getLocalizedProductName(product, lang),
    ProductUtil.getProductContentAmount(product, lang),
  ];
  return nameParts.join(" ").trim();
};

export const formatProductCompact = (product, lang, t) => {
  if ((!product || !product.materialId) && !product.customerAddedProduct) {
    return "";
  }

  let s = "";
  if (product.materialId) {
    s = `${t("productNo")}: ${product.materialId}, `;
  }
  if (product.vnr != null) {
    s = `${s} ${t("vnr").toUpperCase()}: ${product.vnr}, `;
  } else if (product.msiCode != null) {
    s = `${s} ${t("msi").toUpperCase()}: ${product.msiCode}, `;
  }

  const nameAndContentAmount = getProductDisplayName(product, lang);
  return `${s} ${nameAndContentAmount}`;
};
